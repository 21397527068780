import type { RouteLocationNormalized, RouteRecordNormalized } from 'vue-router'
import type { App, Plugin } from 'vue'
import Qs from 'qs'
import { isObject } from 'framework/utils/is'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { useCopyToClipboard } from 'framework/hooks/web/useCopyToClipboard'
import { unref } from 'vue'

// import Speech from 'speak-tts'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

/**
 * @description:  Set ui mount node
 */
export function getPopupContainer(node?: HTMLElement): HTMLElement {
  return (node?.parentNode as HTMLElement) ?? document.body
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  let parameters = ''
  for (const key in obj) {
    parameters += key + '=' + encodeURIComponent(obj[key]) + '&'
  }
  parameters = parameters.replace(/&$/, '')
  return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters
}

// 深度合并
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key])
  }
  return src
}

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature: string[] = []

  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')

  window.open(url, target, feature.join(','))
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route
  const { matched, ...opt } = route
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path,
        }))
      : undefined) as RouteRecordNormalized[],
  }
}

export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

export function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

/**
 * 获取 url 参数
 * @param {string} url 'http://baidu.com?id=1&name=laowang'
 * @returns 出参 { id: 1, name: 'laowang' }
 */
export const getUrlParams = (url: any) => {
  const index = url.indexOf('?')
  if (index === -1) return {}
  const params: any = url.slice(index + 1)
  return Qs.parse(params) as any
}

export function getQueryVariable(variable: string) {
  if (window.location.hash.indexOf('?') == -1) return ''
  const query = window.location.hash.split('?')[1]
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return ''
}
// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {}

  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key])
  })

  return ret as Partial<U>
}
export function findLastIndexOfValue(str: string, cha: string, num: number) {
  let x = str.lastIndexOf(cha)
  for (let i = 0; i < num; i++) {
    x = str.lastIndexOf(cha, x + 1)
  }
  return x
}

//是否中文
export function isChinese(temp: string) {
  const re = /[^\u4E00-\u9FA5]/
  if (re.test(temp)) return false
  return true
}

//获取文本字节长度
export function byteLength(temp: string) {
  let length = 0
  Array.from(temp).map(function (char) {
    if (char.charCodeAt(0) > 255) {
      //字符编码大于255，说明是双字节字符
      length += 2
    } else {
      length++
    }
  })
  return length
}

// export function pxWidth(string, font = 'normal 12px Robot') {
//   const canvas: any = document.createElement('canvas'),
//     context: any = canvas.getContext('2d')
//   font && (context.font = font)
//   const metrics = context.measureText(string)
//   return metrics.width
// }
export function pxWidth(text, fontSize = '12') {
  // 创建临时元素
  // const _span = document.createElement('span')
  // // 放入文本
  // _span.innerText = text
  // // 设置文字大小
  // _span.style.fontSize = fontSize + 'px'
  // // span元素转块级
  // _span.style.position = 'absolute'
  // // span放入body中
  // document.body.appendChild(_span)
  // // 获取span的宽度
  // const width = _span.offsetWidth
  // // 从body中删除该span
  // document.body.removeChild(_span)
  let width = 0
  // eslint-disable-next-line no-control-regex
  const str = text ? text.toString().replace(/[^\x00-\xff]/g, '**') : ''
  if (str && str.length) {
    width = (str.length + 1.5) * (Number(fontSize) / 2 + 1)
  }
  // 返回span宽度
  return Math.ceil(width)
}

export function parseTime2(time, cFormat?: string) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
export function rowSpan(key, target) {
  const arr = target
    .reduce((result, item) => {
      if (result.indexOf(item[key]) < 0) {
        result.push(item[key])
      }
      return result
    }, [])
    .reduce((result, keys) => {
      const children = target.filter((item) => item[key] === keys)
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          [`${key}RowSpan`]: index === 0 ? children.length : 0,
        })),
      )
      return result
    }, [])
  return arr
}
function getDateZeroTime(date) {
  if (typeof date == 'undefined') {
    throw new Error('date is undefined!')
  }

  if (typeof date == 'number') {
    return new Date(new Date(date).toLocaleDateString()).getTime()
  }

  if (typeof date == 'object') {
    return new Date(date.toLocaleDateString()).getTime()
  }
}

//获取当前这周周一凌晨0点的时间戳
export function getMondayTime(date?) {
  const nowTemp = new Date(date) // 当前时间

  const oneDayLong = 24 * 60 * 60 * 1000 // 一天的毫秒数

  const c_time = nowTemp.getTime() // 当前时间的毫秒时间

  const c_day = nowTemp.getDay() || 7 // 当前时间的星期几

  const m_time = c_time - (c_day - 1) * oneDayLong // 当前周一的毫秒时间

  const result: any = getDateZeroTime(m_time) // 当前周一0点时间戳

  return result
}

let cLabel
export function getPageButtonPermission(arr, path, type = 'role_button_config') {
  cLabel = undefined
  for (let i = 0; arr && i < arr.length; i++) {
    const item = arr[i]
    if (item['path'] == path) {
      console.log('item', type, path, item, item[type])
      cLabel = item[type]
      return cLabel
    }

    if (item.children && Array.isArray(item.children) && item.children.length > 0) {
      cLabel = getPageButtonPermission(item.children, path, type)
      if (cLabel != undefined) return cLabel
    }
  }
}

//交换数组位置
export function changeIndex(array: Array<any>, index1: number, index2: number) {
  ;[array[index1], array[index2]] = [array[index2], array[index1]]
}

// 数组排序
export function sortBySortField(jsonData, field = 'sort') {
  // 根据 sort 字段排序
  jsonData.sort((a, b) => (a[field] || 0) - (b[field] || 0))

  // 返回排序后的结果
  return JSON.stringify(jsonData)
}

//用来计算天数 例如：
// planDeliveryDate:2023-06-26 / delayDays:2
export function calculateDeliveryDate(
  planDeliveryDate: string,
  delayDays: number,
  type = 'add',
): string {
  const planDate: Date = new Date(planDeliveryDate)
  const delayMilliseconds: number = delayDays * 24 * 60 * 60 * 1000
  const latestDeliveryTimestamp: number =
    type == 'add' ? planDate.getTime() + delayMilliseconds : planDate.getTime() - delayMilliseconds
  const latestDeliveryDate: Date = new Date(latestDeliveryTimestamp)
  const latestDeliveryDateString: string = latestDeliveryDate.toISOString().split('T')[0]

  return latestDeliveryDateString
}
export function convertToHttps(url) {
  if (url?.startsWith('http://')) {
    return 'https://' + url.slice(7)
  }
  return url
}

export function parseValue(value) {
  // 解析输入值，确保只返回正整数
  const parsedValue = parseInt(value, 10)
  return isNaN(parsedValue) ? 0 : parsedValue
}
export function formatValue(value) {
  // 格式化显示的值，确保只显示正整数
  return parseInt(value, 10)
}
export const filterOption = (input: string, option: any) => {
  /* eslint-disable */
  return (
    ((option?.label || option?.key) &&
      // eslint-disable-next-line no-unsafe-optional-chaining
      (option?.label?.toLowerCase() || option?.key?.toLowerCase()).indexOf(input.toLowerCase()) >=
        0) ||
    false
  )
  /* eslint-disable */
}

//解决后端返回类似于‘83.36',表格 显示83.360000000001精度问题
export const formatNumber = (number = 0) => {
  if (!number || number == 0) return 0
  if (Number.isInteger(number)) {
    return number.toString() // 如果是整数，转换为字符串
  } else {
    const formattedNumber = Number(number).toFixed(2)
    return parseFloat(formattedNumber).toString() // 转换为浮点数，然后再转换为字符串
  }
}

export const formatThousandNumber = (numberValue) => {
  if (!numberValue) return 0
  numberValue = formatNumber(numberValue)
  // 使用正则表达式添加千分位分隔符
  return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getLastPartAfterSlash = (url) => {
  if (!url) return ''
  const parts = url.split('/')
  return parts[parts.length - 1]
}

//用来转换cmd跳转的参数
export const transformCmdQuery = (query) => {
  let queryValue: any = Qs.parse(query)

  //判断是不是时间
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2}/

  // delete queryValue.tab
  delete queryValue.fromTransfer
  for (const key in queryValue) {
    if (queryValue[key] == '') delete queryValue[key]
    else if (['true', 'false'].includes(queryValue[key]))
      queryValue[key] = queryValue[key] === 'true'
    else if (queryValue[key] && Array.isArray(queryValue[key])) {
      for (let index = 0; index < queryValue[key].length; index++) {
        if (typeof queryValue[key][index] == 'string') {
          queryValue[key][index] = +queryValue[key][index]
        }
      }
    } else if (
      typeof queryValue[key] == 'string' &&
      !isNaN(parseInt(queryValue[key], 10)) &&
      !dateTimeRegex.test(queryValue[key])
    ) {
      queryValue[key] = parseInt(queryValue[key], 10)
    }
  }
  return queryValue
}

//将#之类的颜色转成rbga
export const convertHexToRGBA = (hex, opacity) => {
  // 去除 # 号
  hex = hex.replace('#', '')

  // 检查颜色值是否合法
  if (!/^([0-9A-F]{3}){1,2}$/i.test(hex)) {
    return null
  }

  // 将三位颜色值转换为六位
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }

  // 提取红、绿、蓝的值
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // 返回 rgba 格式的颜色值
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

// 语音播报
export const textToSpeech = (text = '') => {
  const speech = new SpeechSynthesisUtterance(text)
  window.speechSynthesis.speak(speech)
  // const speech = new Speech()
  // speech.setLanguage('zh-CN')
  // // speech.init().then(() => {})
  // speech.speak({ text })
}

// 打印面单 PDF
export const printSheet = (url) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      message.warning('面单未获取成功，请稍后重试');
      reject(new Error('URL is required for printing'));
      return;
    }
    fetch(url + `?t=${dayjs().unix()}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.className = 'tmp-pdf';
        iframe.style.display = 'none';
        iframe.src = blobUrl;

        // 监听 iframe 加载完成事件
        iframe.onload = () => {
          // 执行打印操作，并在打印完成后释放资源
          iframe?.contentWindow?.print();
          window.URL.revokeObjectURL(iframe.src)
          resolve('成功'); // 打印成功，解决 Promise
        };

        // 监听 iframe 错误事件
        iframe.onerror = () => {
          message.warning('面单打印失败，请稍后重试');
          reject(new Error('Failed to print the PDF')); // 打印失败，拒绝 Promise
        };

        document.body.appendChild(iframe);
      })
      .catch((error) => {
        message.warning('打印过程中发生错误，请稍后重试');
        reject(error); // 捕获 fetch 或 blob 创建过程中的错误
      });
  });
};

// 复制文本
// **
// * @description: 复制文本
// * @param {string} value
// * @return {void}
// */
export const copyText = (value: string) => {
  const { isSuccessRef } = useCopyToClipboard(value)
  const unSuccessRef = unref(isSuccessRef)
  if (unSuccessRef) {
    message.success('复制成功')
  } else {
    message.error('复制失败')
  }
}