import { LAYOUT } from '@admin/router/constant'

// plm企划管理
const plmBasicConfigurationRouter = {
  path: '/planningManagement',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/planningManagement/planning-attribute',
      name: 'planningAttribute',
      component: () => import('@admin/views/plm/planningManagement/planningAttribute/index.vue'),
      meta: { helpUrl: '', title: '企划属性' },
    },
    {
      path: '/planningManagement/planning-attribute-configuration',
      name: 'planningAttributeConfiguration',
      component: () =>
        import('@admin/views/plm/planningManagement/planningAttributeConfiguration/index.vue'),
      meta: { helpUrl: '', title: '企划属性配置' },
    },
    {
      path: '/planningManagement/planning-bill',
      name: 'planningBill',
      component: () => import('@admin/views/plm/planningManagement/planningBill/list/index.vue'),
      meta: { helpUrl: '', title: '企划单' },
    },
    {
      path: '/planningManagement/planning-bill-add',
      name: 'planningBillAddDetail',
      component: () => import('@admin/views/plm/planningManagement/planningBill/detail/add.vue'),
      meta: { helpUrl: '', title: '企划单新建' },
    },
    {
      path: '/planningManagement/planning-bill-edit',
      name: 'planningBillEditDetail',
      component: () => import('@admin/views/plm/planningManagement/planningBill/detail/edit.vue'),
      meta: { helpUrl: '', title: '企划单详情' },
    },
    {
      path: '/planningManagement/planning-proposal',
      name: 'planningProposal',
      component: () => import('@admin/views/plm/planningManagement/planningProposal/index.vue'),
      meta: { helpUrl: '', title: '企划案查询' },
    },
    {
      path: '/planningManagement/planning-pit-site',
      name: 'planningPitSite',
      component: () => import('@admin/views/plm/planningManagement/planningPitSite/index.vue'),
      meta: { helpUrl: '', title: '企划案坑位管理' },
    },
  ],
}

export default plmBasicConfigurationRouter
